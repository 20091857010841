@import url("https://fonts.cdnfonts.com/css/product-sans");

.main-header {
  font-family: "Product Sans", sans-serif !important;
  width: 100% !important;
  height: 6rem !important;
  align-items: center !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  background: white !important;
  position: relative !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  z-index: 5 !important;
  margin: 0 auto !important;
  padding: 40px;
}

.navbar-nav {
  margin: 0 auto !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 5 !important;
  list-style: none;
  justify-content: right;
  align-items: center;
  width: 100% !important;

}

.link-element {
  z-index: 5 !important;
  margin: 1rem !important;
}
.nav-item {
  z-index: 5 !important;
  margin: 1rem;
}

.nav-link {
  z-index: 5 !important;
  padding: 15px;
  font-size: 20px;
  color: black;
  text-decoration: none;
}

.nav-link:hover,
.nav-link:active {
  z-index: 5 !important;
  opacity: 1;
  border-bottom: 2px solid #b3c000;
  color: #b3c000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-radius: 2px;
}

.dropdown-menu {
  z-index: 5 !important;
  z-index: 1;
  position: absolute;
  padding-bottom: 5px;
  width: 100%;
  top: 43px;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  z-index: 1;
  justify-content: left;
  align-items: left;
}

.dropdown-item {
  z-index: 5 !important;
  padding: 10px;
  justify-content: left;
  align-items: left;
}

.dropdown-item:hover {
  border-bottom: 2px solid #b3c000;
  color: #b3c000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-radius: 2px;
  justify-content: left;
  align-items: left;
}

.dropdown-toggle {
  padding: 15px;
  font-size: 20px;
  color: black;
  text-decoration: none;
  justify-content: left;
  align-items: left;
}

.dropdown-toggle:hover,
.dropdown-toggle:active {
  opacity: 1;
  border-bottom: 2px solid #b3c000;
  color: #b3c000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-radius: 2px;
  justify-content: left;
  align-items: left;
}
.img{
  width: 250px;
}
@media (max-width: 990px) {
  .navbar-nav {
    background: white !important;
  }
  .img{
    width: 200px;
    margin: 0px;
  }
  .nav-item {
    margin: 0 0.5rem;
  }

  .nav-link {
    color: black;
    text-decoration: none;
  }
  .navbar{
    padding-bottom: 100px;
}
}
