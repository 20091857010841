.FAQ__question {
    padding: 20px 0;
    border-bottom: 1px dotted #ccc;
  }
  
  .panel-title {
    font-size: 24px;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 10px 10px 0 48px;
    display: block;
    cursor: pointer;
  }
  
  .panel-content {
    font-size: 20px;
    padding: 0px 14px;
    margin: 0 40px;
    height: 0;
    overflow: hidden;
    z-index: -1;
    opacity: 0;
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    transition: 0.4s ease;
  }
  
  .panel:checked ~ .panel-content {
    height: auto;
    opacity: 1;
    padding: 14px;
  }
  
  .plus {
    position: absolute;
    margin-left: 20px;
    margin-top: 4px;
    z-index: 5;
    font-size: 42px;
    line-height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  .panel:checked ~ .plus {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .panel {
    display: none;
  }