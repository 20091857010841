.ProfilStagiaires{
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #fafafa;
}
.Profil-title{
  position: relative;
  font-size: 20px;
  color: white;
  text-align: center;
  backdrop-filter: blur(20px) saturate(2);
  background-color: #a3af00;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-weight: 600;
  padding: 10px;
  margin: 50px;
  width: auto;
}
.Profil-flex-container{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.Profil-flex-card{
    max-width: 500px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin: 20px;
}
.Profil-flex-card-content{
  padding: 20px;
}
.Profil-flex-title{
  background-color: #a3af00;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 18px;
}
.card {
  width: 300px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 18px;
}

.dev{
  background-color: #1f2f53f1;
}