.custom-alert {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px) saturate(2);
    z-index: 9999;
  }
  
  .custom-alert.show {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-alert-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  
  .custom-alert-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .custom-alert-message {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .custom-alert-button {
    justify-content: center;
    padding: 10px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: solid #a7a7a7 1px;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }

  .custom-alert-button:hover {
    background-color: #b3c000;
    box-shadow: 0px 15px 20px #828282;
    color: #fff;
    transform: translateY(-5px);
  }
  .red{
    background-color: red;
    color: white;
  }
  .red:hover{
    background-color: gray;
    color: black;
  }