.verify-email{
    position: relative;
    display: inline-block;
    justify-content: center;

    height: 100vh;
}
.verify-element{
    display: flex;
    justify-content: center;
}
.nop{
    text-decoration: none;
}
.verify-button {
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    width: 100%;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: solid #a7a7a7 1px;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }
  .verify-button:hover {
    background-color: #b3c000;
    box-shadow: 0px 15px 20px #828282;
    color: #fff;
    transform: translateY(-5px);
  }