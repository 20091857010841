.container-connection {
  display: flex;
  justify-content: center;
}
.login-form,
.register-form {
  margin-top: 50px;
  background-color: #fff;
  display: block;
  padding: 1rem;
  max-width: 350px;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.form-connection-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
}
.input-container {
  position: relative;
}
.form-connection-input {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px 0;
  background-color: #fff;
  padding: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 300px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.form-connection-submit {
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: solid #a7a7a7 1px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
.form-connection-submit:hover {
  background-color: #b3c000;
  box-shadow: 0px 15px 20px #828282;
  color: #fff;
  transform: translateY(-5px);
}
.message {
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}
.error {
  justify-content: center;
  align-items: center;
  display: flex;
  color: red;
}

.slide-enter {
  opacity: 0;
  transform: translateY(-20%);
}
.slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.slide-exit {
  opacity: 1;
  transform: translateY(0);
}
.slide-exit-active {
  opacity: 0;
  transform: translateY(-20%);
  transition: opacity 300ms, transform 300ms;
}
