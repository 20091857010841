.form-container {
  position: relative;
  justify-content: right;
  box-sizing: border-box;
}

.button {
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: solid #a7a7a7 1px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  outline: none;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin: auto;
  display: flex;
}
.button:hover {
  background-color: #b3c000;
  box-shadow: 0px 15px 20px #828282;
  color: #fff;
  transform: translateY(-7px);
}

.formbold-form-wrapper {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  box-sizing: border-box;
}

.formbold-relative {
  position: relative;
}
.formbold-opacity-0 {
  opacity: 0;
}
.formbold-stroke-current {
  stroke: currentColor;
}
#supportCheckbox:checked ~ div span {
  opacity: 1;
}

.formbold-form-wrapper {
  top: 50px;
  right: 0;
  z-index: 1;
  position: absolute;
  margin: 0 auto;
  max-width: 570px;
  width: 100%;
  background: rgb(255, 255, 255);
  padding: 40px;
}
.formbold-mb-3 {
  margin-bottom: 15px;
}
.formbold-relative {
  position: relative;
}
.formbold-opacity-0 {
  opacity: 0;
}
.formbold-stroke-current {
  stroke: currentColor;
}
#supportCheckbox:checked ~ div span {
  opacity: 1;
}

.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.formbold-img {
  margin-bottom: 45px;
}

.formbold-form-title {
  margin-bottom: 30px;
}
.formbold-form-title h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: black;
}
.formbold-form-title p {
  font-size: 16px;
  line-height: 24px;
  color: black;
  margin-top: 12px;
}
.formbold-mb-3{
  width: 100%;
  padding: 0;
}
.formbold-input-flex {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}
.formbold-input-flex > div {
  width: 50%;
}
.formbold-form-input {
  text-align: center;
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  resize: none;
}
.formbold-form-input:focus {
  border-color: #b3c000;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.formbold-form-label {
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}

.formbold-checkbox-label {
  display: flex;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 24px;

}
.formbold-checkbox-label a {
  margin-left: 5px;
}
.formbold-input-checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.formbold-checkbox-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 16px;
  margin-top: 2px;
  border: 0.7px solid #dde3ec;
  border-radius: 3px;
}

.formbold-form-textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  resize: none;
  height: 130px;
  padding: 13px 22px;
}
.formbold-form-textarea:focus {
  border-color: #b3c000;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-btn {
  margin-top: 10px;
  justify-content: center;
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: solid #a7a7a7 1px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
.formbold-btn:hover {
  background-color: #b3c000;
  box-shadow: 0px 15px 20px #828282;
  color: #fff;
  transform: translateY(-5px);
}
.inputText{
  width: 100%;
}
