.custom-alertL {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px) saturate(2);
  z-index: 9999;
}

.custom-alert-contentL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
}
.custom-loader {
  display: flex;
  width:50px;
  height:12px;
  background: radial-gradient(circle closest-side,#565656 90%,#0000) 0 0/33% 100% space;
  clip-path: inset(0 100% 0 0);
  animation:d1 1s steps(4) infinite;
}
@keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}

