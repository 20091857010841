.mainDiv {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
}

.cardStyle {
    display: flex;
    justify-content: center;
    width: 500px;
    border-color: white;
    background: #fff;
    padding: 36px 0;
    border-radius: 4px;
    margin: 30px 0;
    box-shadow: 0px 0 2px 0 rgba(0, 0, 0, 0.25);
}


.formTitle {
    font-weight: 600;
    margin-top: 20px;
    color: #6e6e6e;
    text-align: center;
}

.inputLabel {
    font-size: 12px;
    color: #555;
    margin-bottom: 6px;
    margin-top: 24px;
}

.inputDiv {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin: auto;
}
.imgDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgChange{
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input-change {
    height: 40px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    border: solid 1px #ccc;
    padding: 0 11px;
}

.input-change:disabled {
    cursor: not-allowed;
    border: solid 1px #eee;
}

.buttonWrapper {
    margin-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
}

.submitButton {
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    width: 100%;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: solid #a7a7a7 1px;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}
.submitButton:hover{
    background-color: #b3c000;
    box-shadow: 0px 15px 20px #828282;
    color: #fff;
    transform: translateY(-5px);
}
.submitButton:disabled,
button[disabled] {
    border: 1px solid #cccccc;
    background-color: #cccccc;
    color: #666666;
}

#loader {
    position: absolute;
    z-index: 1;
    margin: -2px 0 0 10px;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #666666;
    width: 14px;
    height: 14px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}