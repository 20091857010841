.form-container-update{
    position: relative;
    justify-content: center;
    margin: 60px;
}
.formbold-form-wrapper-update {
    position: flex;
    top: 50px;
    z-index: 1;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;
}

.formbold-mb-3-update {
    margin-bottom: 15px;
}

.formbold-relative-update {
    position: relative;
}

.formbold-opacity-0-update {
    opacity: 0;
}

.formbold-stroke-current-update {
    stroke: currentColor;
}

.formbold-form-wrapper-update {
    margin: 0 auto;
    max-width: 570px;
    width: 100%;
    background: white;
    padding: 40px;
}

