@import url("https://fonts.cdnfonts.com/css/product-sans");

.form-container-application {
  position: relative;
  justify-content: center;
  margin: 30px;
}
.formbold-form-wrapper-application {
  position: flex;
  top: 50px;
  z-index: 1;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formbold-mb-3-application {
  margin-bottom: 15px;
}

.formbold-relative-application {
  position: relative;
}

.formbold-opacity-0-application {
  opacity: 0;
}

.formbold-stroke-current-application {
  stroke: currentColor;
}

.formbold-form-wrapper-application {
  margin: 0 auto;
  max-width: 500px;
  width: auto;
  background: rgb(255, 255, 255);
  padding: 40px;
}

.file-inputfield{
    border: 1px solid #ccc; 

    text-align: center;
}

@keyframes loader_5191 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.square {
  background: #ddd;
  width: 10px;
  height: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
}

#sq1 {
  margin-top: -25px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 0s infinite alternate;
}

#sq2 {
  margin-top: -25px;
  animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
}

#sq3 {
  margin-top: -25px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 150ms infinite;
}

#sq4 {
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 225ms infinite;
}

#sq5 {
  animation: loader_5191 675ms ease-in-out 300ms infinite;
}

#sq6 {
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 375ms infinite;
}

#sq7 {
  margin-top: 15px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 450ms infinite;
}

#sq8 {
  margin-top: 15px;
  animation: loader_5191 675ms ease-in-out 525ms infinite;
}

#sq9 {
  margin-top: 15px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 600ms infinite;
}
