.EspaceEtudiant {
  margin: 0%;
  min-height: 100vh;
  background: linear-gradient(to bottom, #f5f5f5, #e0e0e0);
  text-align: justify;
}
.Etudiant-title{
    display: flex;
    font-size: 20px;
    color: white;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px) saturate(2);
    background-color: #a3af00;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-weight: 600;
    padding: 10px;
    margin: 50px;
    margin-left: 25%;
    margin-right: 25%;
    max-width: auto;
    text-align: left;
}
.Etudiant-flex-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.Etudiant-flex-card{
    max-width: 1000px;
    width: auto;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    padding: 20px;
}
.Etudiant-flex-title{
    text-align:left;
}